import * as React from "react";
import "../Header/Header.css";
import { Link } from "react-router-dom";
import { CssBaseline } from "@material-ui/core";

function Tab(props) {
  const after_click = () => {
  };

  return (
    <div className="Tabs">
      <a
        onClick={after_click}
        href={"#" + props.tab.label}
        id={"nav_" + props.tab.label}
      >
        {props.tab.label}
      </a>
    </div>
  );
}

export default function Header(props) {
  const tabs_ = [
    //     {
    //     label: "Home",
    //     href: "/",
    // }
    // , {
    //     label: "Products",
    //     href: "/products",
    // }, {
    //     label: "About Us",
    //     href: "/about",
    // }, {
    //     label: "Contact Us",
    //     href: "/contact",
    // }
  ];
  return (
    <React.Fragment>
      <CssBaseline />
      {/* <HideOnScroll {...props}> */}
      {/* <AppBar sx={{ backgroundColor: "red" }}> */}
      <div className="Header" id="Header">
        <Link className="LogoName" to="/">
          <img src={require("../images/logo.png")} alt="Logo" />
        </Link>

        <div className="Tabs">
          {tabs_.map((tab) => (
            <Tab tab={tab} key={tab} />
          ))}
        </div>
      </div>
      {/* </AppBar> */}
      {/* </HideOnScroll> */}
    </React.Fragment>
  );
}