const products=[
            {
                id: 1,
                item_number:"LS343MPK4",
                quantity:1,
                name: 'Alpha Vaccum Flask ',
                code:"alpha",
                description: "Introducing the Alpha Vacuum Flask from 0spill Company: Engineered with cutting-edge vacuum insulation technology, this flask ensures beverages stay at optimal temperatures for extended periods, perfect for both outdoor adventures and leisurely days at home. Its innovative Straw Cap design allows for easy sipping on the move, while the durable powder-coated stainless steel construction provides both reliability and comfort in hand. With a generous 20oz capacity, it seamlessly fits into backpack pockets, purses, or laptop bags, making it the ultimate hydration companion for any occasion. Elevate your drinking experience with the Alpha Vacuum Flask from 0spill Company.",
                mrp: 1499,
                price: 499 ,
                capacity:"500mL",
                features:["Double wall insulation for temperature retention","Cap has a straw spout that is easy to open and comfortable to drink from","Smooth drinking lip for comfortable drinking experience","Durable powder coating for easy grip","Slip-resistant silicone base helps prevent slips","BPA-free"],
                specification:["Height: 9.5\"","Width: 3\"","Depth: 3\"","Weight: 0.9 lbs","20oz | 590ml","Origin: Made in China","Item Numbers: Bright Pink: LS343MPK4","Turquoise: LS343MTQ4, Celery: LS343MCL4, Dark","Denim: LS343MDD4, Carbon: LS343MCN4"],
                colors: ["#3D3C24","#004B93","#62666F","#72281E","#080808","#A8B8D0","#B0903D","#B78727",],
                   
                images: [ 'image1.webp',  'image2.webp' , 'image3.webp', 'image4.webp', 'image5.webp', 'image6.webp', 'image7.webp' ],
                
            },
            {
                id: 2, 
                name: 'Zeta Bottle ',
                code:"zeta",
                item_number:"LS343MPK4",
                quantity:1,
                description: "Our second-in-line premium series ZETA, that accomodates single walled bottle for faster cooling and refrigeration along with our Patented 0SPILL Technology. 0SPILL, an exclusive brand that works upon innovative and futuristic design with no spilling problems.The product accommodates an auto lid-locking feature, that pours water out whenever the pressure is applied either by hand or by lips while drinking. The lid automatically closes as soon as the pressure is released.",
                price: 449 ,
                mrp: 699,
                capacity:"1000mL",
                features:["Double wall insulation for temperature retention","Cap has a straw spout that is easy to open and comfortable to drink from","Smooth drinking lip for comfortable drinking experience","Durable powder coating for easy grip","Slip-resistant silicone base helps prevent slips","BPA-free"],
                specification:["Height: 9.5\"","Width: 3\"","Depth: 3\"","Weight: 0.9 lbs","20oz | 590ml","Origin: Made in China","Item Numbers: Bright Pink: LS343MPK4","Turquoise: LS343MTQ4, Celery: LS343MCL4, Dark","Denim: LS343MDD4, Carbon: LS343MCN4"],
                
                colors: ["#3D3C24","#004B93","#62666F","#72281E","#080808","#A8B8D0","#B0903D","#B78727",],
                images: [ 'image1.webp',  'image2.webp' , 'image3.webp', 'image4.webp', 'image5.webp', 'image6.webp', 'image7.webp' ],
                
            },
            {
                id: 3, 
                name: 'Delta Bottle ',
                code:"delta",
                item_number:"LS343MPK4",
                quantity:1,
                description: "Our third-in-line bestselling premium series DELTA, that accomodates single walled fridge bottles for faster cooling & refrigeration along with our Patented 0SPILL Technology and great aesthetics.0SPILL, an exclusive brand that works upon innovative and futuristic design with no spilling problems.The product accommodates an auto lid-locking feature, that pours water out whenever the pressure is applied either by hand or by lips while drinking. The lid automatically closes as soon as the pressure is released.",
                price: 499 ,
                mrp: 699,
                capacity:"1000mL",
                features:["Double wall insulation for temperature retention","Cap has a straw spout that is easy to open and comfortable to drink from","Smooth drinking lip for comfortable drinking experience","Durable powder coating for easy grip","Slip-resistant silicone base helps prevent slips","BPA-free"],
                specification:["Height: 9.5\"","Width: 3\"","Depth: 3\"","Weight: 0.9 lbs","20oz | 590ml","Origin: Made in China","Item Numbers: Bright Pink: LS343MPK4","Turquoise: LS343MTQ4, Celery: LS343MCL4, Dark","Denim: LS343MDD4, Carbon: LS343MCN4"],
                
               colors: ["#3D3C24","#004B93","#62666F","#72281E","#080808","#A8B8D0","#B0903D","#B78727",],
                images: [ 'image1.webp',  'image2.webp' , 'image3.webp', 'image4.webp', 'image5.webp', 'image6.webp', 'image7.webp' ],
                
            },
       
            {
                id: 4, 
                name: 'Gamma Bottle ',
                code:"gamma",
                item_number:"LS343MPK4",
                quantity:1,
                description: "Our fourth-in-line premium series GAMMA, that accomodates single walled bottle for faster cooling and refrigeration along with our Patented 0SPILL Technology. 0SPILL, an exclusive brand that works upon innovative and futuristic design with no spilling problems.The product accommodates an auto lid-locking feature, that pours water out whenever the pressure is applied either by hand or by lips while drinking. The lid automatically closes as soon as the pressure is released.",
                price: 399 ,
                mrp: 599,
                capacity:"750mL",
                features:["Double wall insulation for temperature retention","Cap has a straw spout that is easy to open and comfortable to drink from","Smooth drinking lip for comfortable drinking experience","Durable powder coating for easy grip","Slip-resistant silicone base helps prevent slips","BPA-free"],
                specification:["Height: 9.5\"","Width: 3\"","Depth: 3\"","Weight: 0.9 lbs","20oz | 590ml","Origin: Made in China","Item Numbers: Bright Pink: LS343MPK4","Turquoise: LS343MTQ4, Celery: LS343MCL4, Dark","Denim: LS343MDD4, Carbon: LS343MCN4"],
                
                colors: ["#3D3C24","#004B93","#62666F","#72281E","#080808","#A8B8D0","#B0903D","#B78727",],
                images: [ 'image1.webp',  'image2.webp' , 'image3.webp', 'image4.webp', 'image5.webp', 'image6.webp', 'image7.webp' ],
                
            }
        ]
export default products