
import './Footer.css'
import React from "react";
import { FaFacebook, FaInstagram, FaLinkedin } from 'react-icons/fa';


const Footer = () => {
  const isMobile = false;
  const location = {
    address: '1600 Amphitheatre Parkway, Mountain View, california.',
    lat: 37.42216,
    lng: -122.08427,
  }

  return (
    <div className="Footer" id='Contact Us'>
      <div className="contact-us">
        <h2 style={{ color: 'white' }}>Contact Details</h2>

        <div className="contact-details">
          <div className="margin-left">
            <h4>Office:</h4>
            <span>
              Oston Technology Pvt. Ltd 45,
              <br />
              Champapura, Kalwar road,
              <br />
              Jaipur,Rajasthan 303706 INDIA
            </span>
          </div>

          <div className="margin-left">
            <h4>Opterating Office:</h4>
            <span>
              111, Krishna Kunj
              <br />
              Gokulpura, Kalwar road,
              <br />
              Jaipur,Rajasthan 302012 INDIA
              <br />

            </span>
          </div>
          {/* <script
            src="https://maps.googleapis.com/maps/api/js?key=AIzaSyB41DRUbKWJHPxaFjMAwdrzWzbVKartNGg&callback=initMap&v=weekly"
            defer
          ></script> */}

          <div className="margin-left">
            <span>
              <b>Email</b>:<br />
              ostontechnology@gmail.com
            </span>
            <br />
            <br />
            <span>
              <b>Phone</b>:<br />
              +91 8890777309
            </span>
          </div>
          <div className="social-icons">
            <a href="https://www.facebook.com/people/O-Spill/100080480404701/" target="_blank" rel="noopener noreferrer">
              <FaFacebook />
            </a>
            {/* <a href="https://twitter.com/your-handle" target="_blank" rel="noopener noreferrer">
          <FaTwitter />
        </a> */}
            <a href="https://www.instagram.com/ospill__/" target="_blank" rel="noopener noreferrer">
              <FaInstagram />
            </a>
            <a href="https://www.linkedin.com/company/oston-technology-private-limited/" target="_blank" rel="noopener noreferrer">
              <FaLinkedin />
            </a>
          </div>
        </div>

        <div className="copyright">2020 © Oston Technology Pvt. Ltd.</div>
      </div>
    </div>
  );
};

export default Footer;

