import React from "react";

function OrderSuccess() {
  const deliveryDate = new Date();
  deliveryDate.setDate(deliveryDate.getDate() + 3);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
        justifyContent: "space-between",
        height: "88.5vh",
      }}
    >
      <div
        className="success-message"
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          alignItems: "center",
        }}
      >
        <div
          className="success-img"
          style={{
            backgroundImage: "url(../../images/success.gif)",
            backgroundPosition: "center",
            backgroundSize: "cover",
            height: "30vh",
            width: "30vw",
          }}
        ></div>
        <h1>Order Successful</h1>
        <div style={{ background: "#ECE6F2", padding: "1rem 1.8rem" }}>
          {`Delivered by ${deliveryDate.toDateString()}`}
        </div>
      </div>
      <p
        style={{
          background: "#E6F6F4",
          width: "100%",
          padding: "1rem",
        }}
      >
        Order details are sent to your Email and Mobile number
      </p>
    </div>
  );
}

export default OrderSuccess;
