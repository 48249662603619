import React, { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "./Checkout.scss";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { Button, Container, FormControl, TextField } from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

function Checkout() {
  const navigate = useNavigate();
  const [total, setTotal] = useState();

  const location = useLocation();

  // Access passed props from location state
  const customProps = location.state && location.state.product;

  console.log("product");
  console.log(customProps);
  const pd = useSelector(({ product }) => product);
  console.log(pd);

  const quantity = pd.quantity;
  const product = pd.product;
  const selected_color = pd.selected_color;
  const textTobePrinted = pd.textTobePrinted;

  const [expanded, setExpanded] = useState("panel1");
  const [formData, setFormData] = useState({
    fullName: "",
    contactNumber: "",
    email: "",
    address: "",
    pincode: "",
  });

  const deliveryDate = new Date();
  deliveryDate.setDate(deliveryDate.getDate() + 3);

  const handleContinueClick = () => {
    const payload = {
      customer_name: formData.fullName,
      customer_number: formData.contactNumber,
      customer_email: formData.email,
      product_id: product.name,
      quantity: quantity,
      address: product.address,
    };

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      customer_name: "test",
      customer_number: "+919929292920",
      customer_email: "sdddd@gmail.com",
      product_id: "alpha",
      quantity: 1,
      address: "ssss",
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://sqqxsg4gm6.execute-api.ap-south-1.amazonaws.com/prod/create-order",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => window.open(result.body.short_url))
      .catch((error) => console.error(error));
  };

  if (quantity === 0) {
    return (
      <div className="no-items">
        <p>No items in the cart.</p>
        <Link to="/">
          <button>Shop Now</button>
        </Link>
      </div>
    );
  } else {
    return (
      <>
        <div className="checkout-section">
          <ToastContainer />
          <Container style={{width:"39rem"}}>
            <Accordion expanded={expanded === "panel1"}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography
                  sx={{ flexShrink: 0, fontWeight: 600, fontSize: "1.5rem" }}
                >
                  Contact Details
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <FormControl fullWidth>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      gap: "1rem",
                      marginBottom: "0.4rem",
                    }}
                  >
                    <TextField
                      required
                      id="outline-required"
                      label="Full Name"
                      name="fullName"
                      value={formData.fullName}
                      onChange={(event) =>
                        setFormData({
                          ...formData,
                          fullName: event.target.value,
                        })
                      }
                      inputProps={{
                        style: { padding: 14 },
                      }}
                      InputLabelProps={{
                        style: { fontSize: 17 },
                      }}
                      style={{
                        width: "100%",
                        fontWeight: "800",
                        height: "100%",
                      }}
                    />
                    <TextField
                      required
                      id="outline-required"
                      label="Email"
                      name="email"
                      value={formData.email}
                      onChange={(event) =>
                        setFormData({ ...formData, email: event.target.value })
                      }
                      inputProps={{
                        style: { padding: 14, height: "2rem" },
                      }}
                      InputLabelProps={{
                        style: { fontSize: 17 },
                      }}
                      style={{ width: "100%" }}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "column",
                      gap: "1rem",
                    }}
                  >
                    <TextField
                      required
                      id="outline-required"
                      label="Mobile Number"
                      value={formData.contactNumber}
                      onChange={(event) =>
                        setFormData({
                          ...formData,
                          contactNumber: event.target.value,
                        })
                      }
                      inputProps={{
                        style: { padding: 14 },
                      }}
                      InputLabelProps={{
                        style: { fontSize: 17 },
                      }}
                      style={{ width: "100%", marginTop:"1rem"  }}
                    />
                    <TextField
                      multiline
                      required
                      id="outline-required"
                      label="Address"
                      value={formData.address}
                      onChange={(event) =>
                        setFormData({
                          ...formData,
                          address: event.target.value,
                        })
                      }
                      inputProps={{
                        style: { padding: 14, height:"5rem"  },
                      }}
                      InputLabelProps={{
                        style: { fontSize: 17},
                      }}
                      style={{ width: "100%" }}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "flex-start",
                      flexDirection: "column",
                      gap: "1rem",
                      marginTop: "0.4rem",
                    }}
                  >
                    <TextField
                      required
                      id="outline-required"
                      label="Pincode"
                      value={formData.pincode}
                      onChange={(event) =>
                        setFormData({
                          ...formData,
                          pincode: event.target.value,
                        })
                      }
                      inputProps={{
                        style: { padding: 14 },
                      }}
                      InputLabelProps={{
                        style: { fontSize: 17 },
                      }}
                      style={{ width: "100%", marginTop:"1rem" }}
                    />
                    <Typography
                      sx={{
                        fontSize: "0.8rem",
                        display: "flex",
                        alignItems: "center",
                        gap: "1rem",
                        color: "#6B3400",
                      }}
                    >
                      <i className="fa-solid fa-truck-fast"></i>
                      {`Delivered by ${deliveryDate.toDateString()}`}
                    </Typography>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "100%",
                    }}
                  >
                    <Button
                      variant="contained"
                      disableElevation
                      sx={{
                        marginTop: "1rem",
                        marginLeft: "1em",
                        width: "50%",
                        height: "3rem",
                        fontWeight: "600",
                        opacity: "1",
                      }}
                      onClick={handleContinueClick}
                    >
                      Continue & Pay Now
                    </Button>
                  </div>
                </FormControl>
              </AccordionDetails>
            </Accordion>
          </Container>
          <div className="checkout-receipt">
            <div className="cart-details">
              {product && (
                <div>
                  <h5>Cart Details</h5>

                  <ul>
                    <li className="product-item" key={product.id}>
                      <div className="item">
                        <div className="item-image">
                          <img
                            src={
                              "https://bytecoder-uat.s3.ap-south-1.amazonaws.com/ospill/website/images/bottles/" +
                              product.code +
                              "/" +
                              selected_color.replace("#", "%23") +
                              "/" +
                              product.images[0]
                            }
                            alt="Product"
                          />{" "}
                        </div>
                        <div className="item-quantity">
                          <p className="item-name">{product.name}</p>
                          <div className="price-quantity">
                            <p className="price">₹{product.price}</p>
                            <p className="quantity">Quantity: {quantity}</p>
                          </div>
                        </div>
                      </div>
                      {textTobePrinted && (
                        <div className="engraving">
                          <span>Engraving</span>
                          <p>{textTobePrinted}</p>
                        </div>
                      )}
                    </li>
                  </ul>

                  <div className="bill-breakdown">
                    <div className="total">
                      Total Cart:
                      <p>₹{product.mrp * quantity}</p>
                    </div>
                    <div className="discount">
                      Discount:
                      <p>
                        - ₹{product.mrp * quantity - product.price * quantity}
                      </p>
                    </div>
                    <div className="subTotal">
                      Sub Total:
                      <p>₹{product.price * quantity}</p>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Checkout;
