import React from "react";
import "./ProductVariant.scss";
function ProductVariant({ product ,selectedColor,selectedImageIndex,handleImageChange}) {
  return (
    <div className="product-variant">
      {product.images.slice(0,5).map((image, index) => (
        <img
          key={index}
          src={"https://bytecoder-uat.s3.ap-south-1.amazonaws.com/ospill/website/images/bottles/" +
            product.code +
            "/" +
            selectedColor.replace("#", "%23") +
            "/" +
            image}
          alt={product.name}
          className={index === selectedImageIndex ? "active selected" : ""}
          onClick={() => handleImageChange(index)}
        />
      ))}
    </div>
  );
}

export default ProductVariant;
