import * as React from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { IoIosCloseCircle } from "react-icons/io";
import "./Upload.scss";
const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export default function InputFileUpload({ onChange, file }) {
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    onChange(file);
  };

  const handleRemoveFile = () => {
    onChange(null);
  };
  return (
    <div className="upload">
      {file ? (
        <>
          <span>{file.name}</span>
          <Button
            onClick={handleRemoveFile}
            variant="text"
            tabIndex={-1}
            startIcon={<IoIosCloseCircle />}
            color="error"
            sx={{ "&:hover": { backgroundColor: "white", color: "#D32F2F" } }}
          ></Button>
        </>
      ) : (
        <Button
          component="label"
          role={undefined}
          variant="outlined"
          tabIndex={-1}
        >
          {file ? `Change image: ${file.name}` : `Upload Personalized Image`}
          <VisuallyHiddenInput
            type="file"
            accept=".png,.jpg"
            onChange={handleFileChange}
          />
        </Button>
      )}

      {/* {file && (
        <img
          src={URL.createObjectURL(file)}
          alt="Uploaded File"
          style={{ maxWidth: "100%", marginTop: "10px" }}
        />
      )} */}
    </div>
  );
}
