import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import products from "../../utils/products";
import "./ProductDetail.scss";
import ProductVariant from "../../components/ProductVariant/ProductVariant";
import Upload from "../../components/UploadButton/Upload";
import ColorVariant from "../../components/ColorVariant/ColorVariant";
import PrimaryButton from "../../components/Buttons/Primary/PrimaryButton";
import BasicTextField from "../../components/TextField/BasicTextField";
import ProductCard from "../../components/Card/ProductCard";
import { useDispatch } from "react-redux";
import { updateProduct } from "../../store/slices/ProductSlice"; // Import the updated action

function ProductDetail() {
  const [backgroundImage, setBackgroundImage] = useState("/images/key-features.png");
  const [selectedFile, setSelectedFile] = useState(null);
  const [customImageURL, setCustomImageURL] = useState("");
  const [customName, setCustomName] = useState("");
  const [imagePosition, setImagePosition] = useState({ top: 456, left: 585 });
  const [textPosition, setTextPosition] = useState({ top: 450, left: 622 });
  const [text, setText] = useState("");
  const [product, setProduct] = useState({
    id: 1,
    item_number: "",
    name: "",
    code: "",
    description: "",
    mrp: 0,
    price: 0,
    capacity: "",
    features: [],
    specification: [],
    colors: [],
    images: [],
  });
  const [selectedColor, setSelectedColor] = useState("");
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [quantity, setQuantity] = useState(1);
  const [showFullDescription, setShowFullDescription] = useState(false);
  const [isMobileView, setIsMobileView] = useState(false);
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    window.scroll(0, 0);
    const foundProduct = products.find((product) => product.id === parseInt(id));
    setProduct(foundProduct);
    setSelectedColor(foundProduct?.colors[0]);
  }, [id]);

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth <= 768) {
        setBackgroundImage("/images/key-feature-mobile.png");
        setIsMobileView(true);
      } else {
        setBackgroundImage("/images/key-features.png");
        setIsMobileView(false);
      }
    }
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleImageChange = (index) => {
    setSelectedImageIndex(index);
  };

  const handleColorChange = (color) => {
    setSelectedColor(color);
  };

  const handleFileUpload = (file) => {
    setSelectedFile(file);
    const reader = new FileReader();
    reader.onload = (e) => {
      setCustomImageURL(e.target.result);
    };
    reader.readAsDataURL(file);
  };

  const handleTextChange = (e) => {
    setText(e.target.value);
    setCustomName(e.target.value);
  };

  const handleDragStart = (e, type) => {
    const data = {
      type,
      offsetX: e.nativeEvent.offsetX,
      offsetY: e.nativeEvent.offsetY,
    };
    e.dataTransfer.setData("application/json", JSON.stringify(data));
  };

  const handleDrop = (e) => {
    e.preventDefault();
    try {
      const data = JSON.parse(e.dataTransfer.getData("application/json"));
      const { offsetX, offsetY, type } = data;
      const { clientX, clientY } = e;
      const newPosition = {
        top: clientY - offsetY,
        left: clientX - offsetX,
      };
      if (type === "image") {
        setImagePosition(newPosition);
      } else if (type === "text") {
        setTextPosition(newPosition);
      }
    } catch (error) {
      console.error("Error parsing drag data:", error);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleQuantityChange = (e) => {
    const newQuantity = parseInt(e.target.value);
    setQuantity(newQuantity);
  };

  const handleClick = () => {
    // Prepare data to be dispatched or saved for checkout
    const customizedProduct = {
      quantity: quantity,
      product: product,
      selected_color: selectedColor,
      customImageURL: customImageURL,
      imagePosition: imagePosition,
      customName: customName,
      textPosition: textPosition,
    };
    dispatch(updateProduct(customizedProduct)); // Dispatching the updateProduct action
    console.log("buying product");
    navigate("/checkout");
  };

  const toggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };

  return (
    <div>
      {product && (
        <>
          <section className="product-detail-section">
            <div className="images-section" onDrop={handleDrop} onDragOver={handleDragOver}>
              <ProductVariant
                product={product}
                selectedColor={selectedColor}
                selectedImageIndex={selectedImageIndex}
                handleImageChange={handleImageChange}
              />
              <div className="selected-image">
                <img
                  src={`https://bytecoder-uat.s3.ap-south-1.amazonaws.com/ospill/website/images/bottles/${product.code}/${selectedColor.replace("#", "%23")}/${product.images[selectedImageIndex]}`}
                  alt={product.name}
                />
                {customImageURL && (
                  <img
                    src={customImageURL}
                    alt="Custom"
                    draggable
                    onDragStart={(e) => handleDragStart(e, "image")}
                    style={{ position: "absolute", top: imagePosition.top, left: imagePosition.left, zIndex: 1, maxWidth: "100px", maxHeight: "100px" }}
                  />
                )}
                {text && (
                  <p
                    draggable
                    onDragStart={(e) => handleDragStart(e, "text")}
                    style={{
                      position: "absolute",
                      top: textPosition.top,
                      left: textPosition.left,
                      transform: "translate(-50%, -50%)",
                      zIndex: 10,
                      // background: "black",
                      color: "white",
                      padding: "5px",
                      borderRadius: "3px",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {text}
                  </p>
                )}
              </div>
            </div>
            <div className="details-section">
              <h2>{product.name}</h2>
              <p>
                {isMobileView
                  ? showFullDescription
                    ? product.description
                    : product.description.slice(0, 100)
                  : product.description}
              </p>
              {isMobileView && !showFullDescription && (
                <button onClick={toggleDescription}>Read More</button>
              )}
              <p style={{ textAlign: "left" }}>{product.item_number}</p>
              <div className="upload-and-name">
                <Upload
                  onChange={(file) => handleFileUpload(file)}
                  file={selectedFile}
                />
                <BasicTextField setText={setText} text={text} onChange={handleTextChange} />
              </div>
              <div className="price-quantity">
                <div className="price">
                  <p>₹ {product.price}</p>
                  <p>₹ {product.mrp}</p>
                </div>
                <div className="quantity-selector">
                  <label>Quantity:</label>
                  <input
                    style={{ width: "5em" }}
                    type="number"
                    value={quantity}
                    min="1"
                    onChange={handleQuantityChange}
                  />
                </div>
              </div>
              <ColorVariant
                product={product}
                selectedColor={selectedColor}
                handleColorChange={handleColorChange}
              />
              <div className="buttons">
                <div className="primary-btn" onClick={handleClick}>
                  Buy Now
                </div>
              </div>
            </div>
          </section>
          <section
            className="key-feature-banner"
            style={{ backgroundImage: `url(${backgroundImage})` }}
          ></section>
          <section
            className="product-banner"
            style={{ backgroundImage: "url(/images/sport-bottle.png)" }}
          ></section>
          <section
            className="bottle-banner"
            style={{ backgroundImage: "url(/images/bottle-detail.png)" }}
          ></section>
          <section className="features-spec-section">
            <div className="features">
              <h2>Features</h2>
              <ul>
                {product.features.map((feature, index) => (
                  <li style={{ listStyle: "disc" }} key={index}>
                    {feature}
                  </li>
                ))}
              </ul>
            </div>
            <div className="specs">
              <h2>Specs</h2>
              <ul>
                {product.specification.map((specs, index) => (
                  <li style={{ listStyle: "disc" }} key={index}>
                    {specs}
                  </li>
                ))}
              </ul>
            </div>
          </section>
          <section className="products-card-section">
            <h2>You May Also Like</h2>
            <div className="container">
              {products.map((product) => (
                <ProductCard key={product.id} product={product} />
              ))}
            </div>
          </section>
        </>
      )}
    </div>
  );
}

export default ProductDetail;
