import './Product.css';
import React from "react";
import { useState, useEffect, Component } from 'react';
import ProductDetails from '../ProductDetails/ProductDetailsWithCart';
import { Paper } from '@material-ui/core';



function Slideshow(props) {
    const [index, setIndex] = useState(0)

    useEffect(() => {
        setIndex(0)
    }, [])

    const next = () => {
        if (index === props.items.length - 1) {
            setIndex(0)
        } else {
            setIndex(index + 1)
        }
    }
    const prev = () => {
        if (index === 0) {
            setIndex(props.items.length - 1)
        } else {
            setIndex(index - 1)
        }
    }

    return (
        <div className='KnowMore'>

            <div onClick={prev} className="SlideButton"> ❮ </div>

            <div className='KnowMoreImages'>
                <div>
                    <img src={props.items[index].image1} />
                    <p>{props.items[index].tag1} </p>
                </div>
                <div>
                    <img src={props.items[index].image2} />
                    <p>{props.items[index].tag2}</p>
                </div>
            </div>

            <div onClick={next} className="SlideButton"> ❯ </div>

        </div>

    )
}



class DisplayProducts extends Component {

    constructor() {
        super();
        this.state = { color: "red" };


    }

    render() {
        const { products } = this.props

        return (<div className='ProductMain' id='Products'>

            <div className='Products'>

                <div>
                    <h2>
                        Products
                    </h2>
                    {products.map((item) =>

                        <div className='Product'>

                            <Paper elevation={3}>
                                <ProductDetails product={item} />

                            </Paper>

                        </div>
                    )}

                </div>


            </div>

        </div>)
    }

}

export default DisplayProducts;