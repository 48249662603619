import React from "react";
import './ColorVariant.scss'
function ColorVariant({product,selectedColor,handleColorChange}) {
  return (
    <div className="color-selector">
      {product.colors.map((color, index) => (
        <div
          key={index}
          className={`color ${color === selectedColor ? "selected" : ""}`}
          style={{ backgroundColor: color }}
          onClick={() => handleColorChange(color)}
        ></div>
      ))}
    </div>
  );
}

export default ColorVariant;
