import React from "react";
import "./Card.scss";
import { Link } from "react-router-dom";

function ProductCard({ product }) {
  return (
    <div className="card-content">
      <div className="product-image">
        <img
          src={"https://bytecoder-uat.s3.ap-south-1.amazonaws.com/ospill/website/images/bottles/" +
            product.code +
            "/" +
            product.colors[4].replace("#", "%23") + 
            "/" +
            product.images[0]}
          alt={product.name}
        />
      </div>
      <div className="detail">
        <div className="price">
          <h3>{product.name}</h3>
          <p>Price<span>₹{product.price}</span></p>
        </div>

        <p>Capacity<span>{product.capacity}</span></p>
        <p>Material<span>Stainless Steel</span></p>
        <p>Weight<span>~260 gm</span></p>
        <p>Type<span>Single Wall</span></p>
        <p>Packing <span>40/80 pcs</span></p>
      </div>

      <div className="button">
        <button>
          <Link to={`/productDetail/${product.id}`}>Shop now</Link>
        </button>
      </div>
    </div>
  );
}
export default ProductCard;
