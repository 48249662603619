import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App/App';
import Header from './Header/Header';
import Footer from './Footer/Footer';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import ProductDetail from './pages/ProductDetailPage/ProductDetail';
import products from './utils/products';
import Checkout from "./pages/CheckoutPage/Checkout"
import OrderSucces from "./pages/Success/OrderSuccess"
import { Provider } from 'react-redux';
import store from './store/store'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

  <Provider store={store}>

    <div className='Main'>

      {/* <HideAppBar /> */}
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path="/" element={<App />} />
          <Route path="/productDetail/:id" element={<ProductDetail products={products} />} />
          <Route path='/checkout' element={<Checkout />} />
          <Route path='/success' element={<OrderSucces />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div >

  </Provider>


);

