import React from 'react';
import './App.css';
import DisplayProducts from '../components/Products/Product'
import Example from '../components/MainCarousel/MainCarousel';
import AboutComponent from '../components/About/AboutComponent';
import GalleryStrip from '../components/Gallery/GalleryStrip'
import products from '../utils/products';
import TestimonialCard from '../components/Testimonials/TestimonialCard'
import Partners from '../components/Partners/Partners'
import Awards from '../components/AwardCard/AwardCard'



function App() {

  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  return (
    <div className="App">
      <Example />

      {/* <MainSlider /> */}
      {/* <OurProducts /> */}
      {/* <Why /> */}
      {/* <WhyExample /> */}
      <AboutComponent />
      <DisplayProducts products={products} />

      <TestimonialCard />
      <Awards/>
      <Partners />
      <GalleryStrip></GalleryStrip>

      {/* <div className='WhatsApp' onClick={() => openInNewTab('https://api.whatsapp.com/send?phone=917700023403&text=i+am+interested+to+buy+your+water+bottles')} >
        <img src={wap} />
      </div> */}

    </div>
  );
}

export default App;
