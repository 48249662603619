import { createSlice } from "@reduxjs/toolkit";
import { get, set } from "lodash";

function setupApiEndpointBase(runEnv) {
  const apiBase = {}
  if (runEnv.startsWith('ft')) {
    apiBase.authServerBase = `https://${runEnv}auth.uat1.kalfin.in`;
    apiBase.kalServerBase = `https://${runEnv}server.uat1.kalfin.in`;
    apiBase.sbmServerBase = `https://${runEnv}bankingserver.uat1.kalfin.in`;
  } else if (['uat', 'preprod'].includes(runEnv)) {
    apiBase.authServerBase = `https://auth.${runEnv}1.kalfin.in`;
    apiBase.kalServerBase = `https://${runEnv}server.${runEnv}1.kalfin.in`;
    apiBase.sbmServerBase = `https://bankingserver.${runEnv}1.kalfin.in`;
  } else if (runEnv === 'prod') {
    apiBase.authServerBase = 'https://auth.kaleidofin.com';
    apiBase.kalServerBase = 'https://server.kaleidofin.com';
    apiBase.sbmServerBase = 'https://bankingserver.kaleidofin.com';
  } else {
    console.log(`${runEnv} will use from .env.development file`)
    apiBase.authServerBase = process.env.REACT_APP_AUTH_SERVER_BASE+'/kaleidofin-auth';
    apiBase.kalServerBase = process.env.REACT_APP_KAL_SERVER_BASE;
    apiBase.sbmServerBase = process.env.REACT_APP_BANK_SERVER_BASE;
  }
  return apiBase;
}

export const ApplicationPropertiesSlice = createSlice({
  name: "applicationProperties",
  initialState: { 
    base: {
      authServerBase: '',
      kalServerBase: '',
      sbmServerBase: '',
    }, 
    paths: {
      authServer: {
        tokenUrl: 'oauth/token',
      },
      kalServer: {
        sendOrVerifyOtp: 'api/anonymous-v2/otp',
        bankAccountDetails: 'api/direct/sa/customer/bankAccountDetails'
      },
      bankServer: {
        sendOrVerifyOtp: 'banking/api/customer/otp',
        bankAccountDetails: 'banking/api/customer/bankAccountDetails'
      },
    } 
  },
  reducers: {
    updateApplicationProperties: (state, action) => {
      set(state, "base", setupApiEndpointBase(get(action, 'payload.runEnv')));
    },
  },
});

export const { updateApplicationProperties } = ApplicationPropertiesSlice.actions;

export default ApplicationPropertiesSlice.reducer