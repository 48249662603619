import { createSlice } from "@reduxjs/toolkit";

export const ProductSlice = createSlice({
  name: "productInfo",
  initialState: {
    quantity: 0,
    product: {},
    selected_color: "",
    customImageURL: "",
    imagePosition: { top: 0, left: 0 },
    customName: "",
    textPosition: { top: 0, left: 0 },
  },
  reducers: {
    updateProduct: (state, action) => {
      state.quantity = action.payload.quantity;
      state.product = action.payload.product;
      state.selected_color = action.payload.selected_color;
      state.customImageURL = action.payload.customImageURL;
      state.imagePosition = action.payload.imagePosition;
      state.customName = action.payload.customName;
      state.textPosition = action.payload.textPosition;
    },
  },
});

export const { updateProduct } = ProductSlice.actions;

export default ProductSlice.reducer;
