import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";

export default function BasicTextFields({text,setText}) {
  const handleText=(e)=>{
    setText(e.target.value);
  }
  return (
    <Box
      component="form"
      sx={{
        "& > :not(style)": { m: 1, width: "25ch" },
      }}
      noValidate
      autoComplete="off"
    >
      <TextField
        id="outlined-basic"
        label="Enter name to be printed"
        variant="outlined"
        value={text}
        onChange={handleText}
      />
    </Box>
  );
}
