import { createSlice } from "@reduxjs/toolkit";
import { set } from "lodash";

export const OtpSlice = createSlice({
  name: "otpInfo",
  initialState: {
    mobileNumber: "",
    otp: "",
    otpSentStatus: false,
    otpVerification: false,
    enableInvestments: false,
    error: false,
    errorOTP: false,
    otpLimitExceeded: false,
  },
  reducers: {
    updateOtp: (state, action) => {
      set(state, "otp", action.payload);
    },
    updateOtpVerification: (state, action) => {
      set(state, "otpVerification", action.payload);
    },
    updateOtpSentStatus: (state, action) => {
      set(state, "otpSentStatus", action.payload);
    },
    updateInvestmentsPreference: (state, action) => {
      set(state, "enableInvestments", action.payload);
    },
    updateMobileNumber: (state, action) => {
      set(state, "mobileNumber", action.payload);
    },
    updateFieldError: (state, action) => {
      set(state, "error", action.payload);
    },
    updateOTPFieldError: (state, action) => {
      set(state, "errorOTP", action.payload);
    },
    updateOTPLimit: (state, action) => {      
      set(state, "otpLimitExceeded", action.payload);
    },
  },
});

export const {
  updateMobileNumber,
  updateOtp,
  updateOtpSentStatus,
  updateOtpVerification,
  updateInvestmentsPreference,
  updateFieldError,
  updateOTPFieldError,
  updateOTPLimit
} = OtpSlice.actions;

export default OtpSlice.reducer;
