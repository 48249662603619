import React from "react";
import "./AwardCard.scss";




const awards = [
  {
    id: 1,
    award_title: "",
    award_img_url: "/images/award1.png"
  },
  {
    id: 4,
    award_title: "",
    award_img_url: "/images/award4.webp"
  },
  {
    id: 2,
    award_title: "",
    award_img_url: "/images/award2.webp"
  },
  {
    id: 3,
    award_title: "",
    award_img_url: "/images/award3.webp"
  },
  
  {
    id: 5,
    award_title: "",
    award_img_url: "/images/award5.webp"
  },
  {
    id: 6,
    award_title: "",
    award_img_url: "/images/award6.webp"
  },
  {
    id: 7,
    award_title: "",
    award_img_url: "/images/award7.webp"
  }, {
    id: 8,
    award_title: "",
    award_img_url: "/images/award8.webp"
  }, {
    id: 9,
    award_title: "",
    award_img_url: "/images/award9.webp"
  }, {
    id: 10,
    award_title: "",
    award_img_url: "/images/award10.webp"
  },
];



function AwardCard({ image, title }) {
  return (
    <div
      className="award-card"
      style={{ backgroundImage: `url(${image})` }}
    >
      <div className="overlay"></div>
      <div className="award-card-content">
        <img src="/images/award-logo.png" alt="" className="hover-image" />
        <p>{title}</p>
      </div>
    </div>
  );
}



export default function Awards() {
  return (
    <section className="award-section">
      <h2>
        Celebrating Our <span>Achievements and Accolades</span>
      </h2>
      <div className="award-container">
        {awards.map((award) => (
          <AwardCard
            key={award.id}
            image={award.award_img_url}
            title={award.award_title}
          />
        ))}
      </div>
    </section>
  )
} 
