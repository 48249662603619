import React from 'react';
import Carousel from 'react-material-ui-carousel'
import { Box, Paper } from '@mui/material'


import w1 from '../../images/w1.webp';
import w2 from '../../images/w2.webp';
import w3 from '../../images/w3.webp';

import './MainCarousel.css'

function Example(props) {

  const items = [

    w1, w2, w3
  ]
  return (
    <div id='Home'>
      <Paper>
      <Carousel className='MainCarousel'
      style={{
        height:'1040px'
      }}
        swipe='true'
        interval='2000'
        navButtonsAlwaysInvisible='true'
        indicatorContainerProps={{
          style: {
            display: 'none',
            Padding: '0px',
          }
        }}
        activeIndicatorIconButtonProps={{
          style: {
            // backgroundColor: 'red' // 2
          }
        }}
        indicatorIconButtonProps={{
          style: {
            // padding: '10px',    // 1
            // color: 'blue',      // 3
          }
        }}

        navButtonsProps={{
          style: {
            backgroundColor: 'blue'
          }
        }}

      >
        {
          items.map((item, i) => <Item key={i} item={item} />)
        }
      </Carousel>
      </Paper>
    </div>
  )
}

function Item(props) {
  return (

    <div >
      <Box sx={{
        height: "70%",
        // padding: '2em'

      }}>
        <img
          src={props.item}
          alt={props.item}
          style={{
            // borderRadius: "5%",
            height: "100%",
            width: "100%",
          }}
        />
      </Box>
    </div>

  )
}

export default Example;