import React, { useState } from "react";
import "./ProductDetailsWithCart.css";
import { Link } from "react-router-dom";

const ProductDetails = ({ product }) => {
  const [selectedColor, setSelectedColor] = useState(product.colors[0]);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  

  const handleColorChange = (color) => {
    setSelectedColor(color);
  };

  const handleImageChange = (index) => {
    setSelectedImageIndex(index);
  };

  // const handleAddToCart = (url) => {
  //   // Handle adding the product to the cart (not implemented in this example)
  //   console.log(`Added ${quantity} ${selectedColor} ${product.name} to cart`);
  //   const newWindow = window.open(url, "_blank", "noopener,noreferrer");
  //   if (newWindow) newWindow.opener = null;
  // };

  return (
    <div className="product-details">
      <div className="product-images">
        <div className="selected-image">
          <img
            src={"https://bytecoder-uat.s3.ap-south-1.amazonaws.com/ospill/website/images/bottles/" +
              product.code +
              "/" +
              selectedColor.replace("#", "%23") +
              "/" +
              product.images[selectedImageIndex]}
            alt={product.name}
          />
        </div>
        <div className="thumbnail-images">
          {product.images.map((image, index) => (
            <img
              key={index}
              src={"https://bytecoder-uat.s3.ap-south-1.amazonaws.com/ospill/website/images/bottles/" +
                product.code +
                "/" +
                selectedColor.replace("#", "%23") +
                "/" +
                image}
              alt={product.name}
              className={index === selectedImageIndex ? "active" : ""}
              onClick={() => handleImageChange(index)}
            />
          ))}
        </div>
      </div>
      <div className="product-info">
        <h3>{product.name}</h3>
        {/* <span class="a-price-symbol">₹</span> */}

        <p>
          MRP: <s style={{ color: "red" }}>₹{product.mrp} </s> ₹{product.price}
        </p>
        <p>Capacity: {product.capacity}</p>

        <div className="product-buy">
          <div className="color-selector">
            {product.colors.map((color, index) => (
              <div
                key={index}
                className={`color ${color === selectedColor ? "selected" : ""}`}
                style={{ backgroundColor: color }}
                onClick={() => handleColorChange(color)}
              ></div>
            ))}
          </div>
          
          <div className="add-cart">
            <Link to={`/productDetail/${product.id}`}>
              <button>Shop now</button>
            </Link>
          </div>
          <p className="Description">{product.description}</p>
        </div>
      </div>
    </div>
  );
};

export default ProductDetails;
