import React from "react";
import "./PrimaryButton.css";
import { Link } from "react-router-dom";
function PrimaryButton() {
  return (
    <Link
      to="/checkout"
      className="primary-btn"
    >
      Buy Now
    </Link>
  );
}

export default PrimaryButton;
