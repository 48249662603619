import { configureStore } from "@reduxjs/toolkit";
import OtpSlice from "./slices/OtpSlice";
import ApplicationPropertiesSlice from "./slices/ApplicationPropertiesSlice";
import ProductSlice from "./slices/ProductSlice";


// NOTE: Centralised store
const store = configureStore({
  reducer: {
    product: ProductSlice,
    otp: OtpSlice,
    applicationProperties: ApplicationPropertiesSlice,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
export default store;